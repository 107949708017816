<template>    
    <section class="process section-padding grey-color-bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="text-center mb-10">
                        <h4>Work Process</h4>
                    </div>
                </div>    
            </div>
            <div class="row">
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="item">
                        <div class="item-icon"><img v-lazy="`${publicPath}images/icons/process-icon-1.svg`" alt="Upload PSD"></div>   
                        <span class="indication item-one">01</span>
                        <h6>Upload <br> Design</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="item">
                        <div class="item-icon"><img v-lazy="`${publicPath}images/icons/process-icon-2.svg`" alt="HTML Convert"></div>   
                        <span class="indication item-two">02</span>
                        <h6>HTML <br> Coversion</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="item">
                        <div class="item-icon"><img v-lazy="`${publicPath}images/icons/process-icon-3.svg`" alt="Integration"></div>   
                        <span class="indication item-three">03</span>
                        <h6>Website <br> Integration</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="item">
                        <div class="item-icon"><img v-lazy="`${publicPath}images/icons/process-icon-4.svg`" alt="Browser Friendly"></div>   
                        <span class="indication item-four">04</span>
                        <h6>Browser <br> Compatiblity</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="item">
                        <div class="item-icon"><img v-lazy="`${publicPath}images/icons/process-icon-5.svg`" alt="Project Delivery"></div>   
                        <span class="indication item-five">05</span>
                        <h6>Project <br> Delivery</h6>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <div class="item item-last">
                        <div class="item-icon"><img v-lazy="`${publicPath}images/icons/process-icon-6.svg`" alt="Technical Support"></div>   
                        <span class="indication item-six">06</span>
                        <h6>Technical <br> Support</h6>
                    </div>
                </div>
            </div>           
        </div>
    </section>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
